import setUpVideoModal from './features/video-modal';
import setUpIframeResizer from './features/iframe-resizer';

(function () {

    // Remove the no-js class from the <html> element
    document.documentElement.classList.remove('no-js');


    // TOAST
    function getCookie(name) {
        var v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
        return v ? v[2] : null;
    }

    function setCookie(name, value, days) {
        var d = new Date();
        d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
        document.cookie =
            name + "=" + value + ";path=/;expires=" + d.toGMTString();
    }

    function setAlertCookie(name, value) {
        document.cookie =
            name + "=" + value + ";path=/";
    }

    var toastsDismissed = (getCookie("alert-toast-dismissed") || "").split(",");
    var toastEls = document.querySelectorAll(".js-alert");
    Array.prototype.forEach.call(toastEls, function (toastEl, i) {
        var toastId = toastEl.getAttribute("data-alert-id");

        if (toastsDismissed.indexOf(toastId) === -1) {
            toastEl.classList.remove("c-alert--dismissed");
        }

        var toastDismissEls = toastEl.querySelectorAll(".js-alert-dismiss");
        for (var j = 0; j < toastDismissEls.length; j++) {
            var toastDismissEl = toastDismissEls[j];

            toastDismissEl.addEventListener("click", function (e) {
                resetSticky();
                toastEl.classList.add("c-alert--dismissed");
                setAlertCookie(
                    "alert-toast-dismissed",
                    "true"
                );
                checkAlerts();
            });
        }
        checkAlerts();
    })

    //If any are active add class to container
    function checkAlerts() {
        var toastEls = document.querySelectorAll(".js-alert");
        var allToastsDismissed = true;

        var alertCookie = getCookie("alert-toast-dismissed");
        Array.prototype.forEach.call(toastEls, function (toastEl, i) {
            if (alertCookie == null || alertCookie == undefined || alertCookie == "") {
                allToastsDismissed = false;
            }
        })

        if (allToastsDismissed) {
            document.getElementById("alerts-panel").classList.add("c-alerts--dismissed");
        }
    }


    //MOBILE MENU
    if (!window.matchMedia("(min-width:1024px)").matches) {
        function toggleMenu() {
            document.getElementById("header").classList.toggle("c-header--menu-open");
        }

        document.getElementById("menu-btn").addEventListener("click", toggleMenu)

        var sublinks = document.querySelector(".js-sublinks");
        var dropdowns = document.getElementsByClassName("js-menu-dropdown");
        Array.prototype.forEach.call(dropdowns, function (dropdown, i) {
            dropdown.addEventListener("click", function (e) {
                e.preventDefault();
                dropdown.nextElementSibling.classList.toggle("c-menu__sub-items--open");
                dropdown.classList.toggle("c-menu__dropdown-link--active");

                //if closing first level item
                if (!dropdown.nextElementSibling.classList.contains("c-menu__sub-items--open") && dropdown.classList.contains("c-menu__dropdown-link--first-level")) {
                    //close children
                    var openItems = document.getElementsByClassName("c-menu__sub-items--open");
                    Array.prototype.forEach.call(openItems, function (el, i) {
                        el.classList.remove("c-menu__sub-items--open");
                        el.previousElementSibling.classList.remove("c-menu__dropdown-link--active");
                    });
                }
            })

        });

        var backlinks = document.querySelectorAll(".js-back-link");
        Array.prototype.forEach.call(backlinks, function (backlink, i) {
            backlink.addEventListener('click', function (e) {
                e.preventDefault();
                backlink.parentNode.classList.remove("c-menu__sub-items--open");
            })
        })

        var links = document.querySelectorAll('a[href^="#"]');
        Array.prototype.forEach.call(links, function (anchor, i) {
            anchor.addEventListener('click', function (e) {
                e.preventDefault();

                document.querySelector(this.getAttribute('href')).scrollIntoView({
                    behavior: 'smooth',
                });
            });
        });
    }

    /**
     * Handle the scrolled header
     */
    var header = document.getElementById("header");
    var sticky = header.offsetTop;
    ///var topButton = document.getElementById("back-to-top-wrapper");
    var menuBP = window.matchMedia("(min-width:960px)");
    updateSubLevels();


    function resetSticky() {
        setTimeout(() => {
            header.style.top = 0;
            updateSubLevels();
        }, 1);
        setTimeout(function () {
            sticky = header.offsetTop;
        }, 2)
    }


    header.isScrolled = false; // Set a flag to hook onto later
    header.style.willChange = "background"; // Let the browser know we'll change the background


    window.onscroll = function () {
        // Check if the browser supports requestAnimationFrame, if so we'll hook onto that
        if (window.requestAnimationFrame) {
            window.requestAnimationFrame(scrolledHeader);
        } else {
            scrolledHeader;
        }
    };

    // updated sublevel nav position
    function updateSubLevels() {
        var sublevels = document.querySelectorAll(".c-header-wrapper--search-bar-hidden .c-menu__sublevel");
        sublevels.forEach(x => {
            try {
                x.style.top = document.querySelector("#alerts-panel.c-alerts--dismissed") == null ? (110 + document.querySelector("#alerts-panel.c-alerts").offsetHeight) + "px" : "110px";

            }
            catch {
                x.style.top = document.querySelector(".c-header").offsetHeight + "px";
            }
        });
    }

    function scrolledHeader() {
        //console.log("sticky: " + sticky);
        //console.log("offsetTop: " + getScrollTop());
        //console.log("header Offset: " + header.offsetTop);

        // is alert open?
        if (document.querySelector("#alerts-panel.c-alerts--dismissed") === null) {
            header.classList.add('c-header--scrolled');
            const alertsEl = document.querySelector("#alerts-panel.c-alerts");
            if (alertsEl != null) {
                header.style.top = alertsEl.offsetHeight + "px";
            }
        } else {
            header.classList.add('c-header--scrolled');
            header.style.top = 0;
            header.style.willChange = "";
            header.isScrolled = true;
        }

        /*if (getScrollTop() > sticky) {
            header.classList.add('c-header--scrolled');
            //topButton.classList.add('c-btn__back-to-top--scrolled');
            header.style.willChange = "";
            header.isScrolled = true;
        } else {
            header.classList.remove('c-header--scrolled');
            //topButton.classList.remove('c-btn__back-to-top--scrolled');
            header.style.willChange = "background";
            header.isScrolled = false;
        }*/
    }

    function getScrollTop() {
        if (typeof pageYOffset != 'undefined') {
            //most browsers except IE before #9
            return pageYOffset;
        } else {
            var B = document.body; //IE 'quirks'
            var D = document.documentElement; //IE with doctype
            D = (D.clientHeight) ? D : B;
            return D.scrollTop;
        }
    }

    /*document.getElementById("back-to-top").addEventListener("click", function () {
        window.scrollTo({top: 0, behavior: 'smooth'});
    })*/

    var lists = document.getElementsByTagName("UL");
    Array.prototype.forEach.call(lists, function (list, i) {
        if (list.style.listStyleType != "") {
            list.classList.add("t-rich_text--remove-chevrons");
        }
    })

    var numberedLists = document.getElementsByTagName("OL");
    Array.prototype.forEach.call(numberedLists, function (list, i) {
        if (list.style.listStyleType != "") {
            list.classList.add("t-rich_text--remove-numbers");
        }
    })

    var navSearchButton = document.querySelectorAll('.js-nav-search-button');
    if (navSearchButton) {
        navSearchButton.forEach(button => {
            button.addEventListener('click', (e) => {
                document.querySelector('.js-header-wrapper').classList.toggle('c-header-wrapper--search-bar-hidden');
            })
        })

    }

    window.fileInputChange = function(id, input){
        var $label = $("#label-" + id);
        var $btn = $("#btn-" + id);

        if (input.value === ""){
            $label[0].innerText = "Drag and drop files here or"
            $($btn[0]).show();
        } else {
            var theSplit = input.value.split('\\');
            $label[0].innerText = theSplit[theSplit.length - 1];
            $($btn[0]).hide();
        }
    }
    
    document.addEventListener('DOMContentLoaded', () => {
        console.log("Dom loaded")
        document.querySelectorAll('.js-video-modal').forEach(rootEl => setUpVideoModal(rootEl));
        document.querySelectorAll('.js-iframe-resizer').forEach(rootEl => setUpIframeResizer(rootEl));
    })
})();
